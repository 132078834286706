.Summary {
  position: sticky;
  top: 6rem;

  ul {
    list-style-type: none !important;
    margin-left: 0 !important;

    li {
      margin-left: 0
    }
  }

  select {
    width: 100%;
  }

  label {
    font-size: 0.8rem;
  }
}

.Notice {
  margin-top: -5px;
  font-size: 0.9rem;
  margin-bottom: 10px;
}