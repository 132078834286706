.PresentationPdf {
  &.Pdf {
    > div {
      height: calc(100vh - 84px - 70px);
    }
  }
}

.Link {
  margin-top: 15px;
  font-size: 1.5rem;
  text-decoration: underline;
  cursor: pointer;
}
