@import "variables";

// Import only what you need from Bulma
@import '../../node_modules/bulma/sass/utilities/_all.sass';
@import '../../node_modules/bulma/sass/base/_all.sass';

// Layout
@import '../../node_modules/bulma/sass/layout/hero.sass';
@import '../../node_modules/bulma/sass/layout/section.sass';
@import '../../node_modules/bulma/sass/grid/columns.sass';

// Elements
@import '../../node_modules/bulma/sass/elements/box.sass';
@import '../../node_modules/bulma/sass/elements/content.sass';
@import '../../node_modules/bulma/sass/elements/button.sass';
@import '../../node_modules/bulma/sass/elements/container.sass';
@import '../../node_modules/bulma/sass/elements/title.sass';
@import '../../node_modules/bulma/sass/elements/notification.sass';
@import '../../node_modules/bulma/sass/elements/table.sass';
@import '../../node_modules/bulma/sass/elements/icon.sass';
@import '../../node_modules/bulma/sass/elements/progress.sass';
@import '../../node_modules/bulma/sass/elements/tag.sass';

// Components
@import '../../node_modules/bulma/sass/components/navbar.sass';
@import '../../node_modules/bulma/sass/components/modal.sass';
@import '../../node_modules/bulma/sass/components/card.sass';
@import '../../node_modules/bulma/sass/components/message.sass';

// Form
@import '../../node_modules/bulma/sass/form/_all.sass';

.modal-background {

}

.has-text-centered {
  text-align: center !important;
}

.buttons.has-addons .button.is-selected {
  z-index: 0 !important;
}

.content .table td {
  padding-bottom: 0.5em;
  vertical-align: middle;
}

p.help {
  margin-top: -10px;
}

.Toastify__toast--info a {
  color: white;
  text-decoration: underline;
}

.color-gray4 {
  color: $gray4;
}

.color-gray3 {
  color: $gray3;
}
