.Home {
}

.Presentations, .Listings, .Order, .Promotions {
  display: block;

  .Header {
    height: 170px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    p {
      color: white;
      font-size: 1.6rem;
      justify-content: center;
      transition: all 300ms ease-in-out;
    }
  }

  .Content {
    min-height: 132px;
    font-size: 0.9rem;
  }

  &:hover {
    p {
      transform: scale(1.1);
    }
  }
}

.Promotions {
  .Header {
    background-color: dodgerblue;
  }
}

.Presentations {
  .Header {
    background-color: #F37335;
  }
}

.Listings {
  .Header {
    background-color: #93291E;
  }
}

.Order {
  .Header {
    background-color: #11998e;
  }
}

.News {
  display: flex;
  margin-bottom: 10px;

  .Date {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #efefef;
    width: 150px;
    min-width: 150px;
  }

  .Content {
    padding: 20px;
  }
}
