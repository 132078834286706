@import '../../styles/variables';

.PartnerBadge {
  font-weight: bold;
  display: inline-flex;
  align-items: center;

  img {
    margin-left: 0.8rem;
  }

  &.Base {
    color: $primary;
  }

  &.Bronze {
    color: #B4421B;
  }

  &.Silver {
    color: #595C5D;
  }

  &.Gold {
    color: #BA8214;
  }

  &.Platinum {
    color: #959899;
  }
}
