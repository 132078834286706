@charset "utf-8";

$gray5: #666666;
$gray4: #888888;
$gray3: #aaaaaa;
$gray2: #dddddd;
$gray1: #eeeeee;

$success: #39c371;
$danger: #ff2746;
$warning: #ffb229;

// Update Bulma's global variables
$grey-dark: $gray5;
$grey-light: $gray1;
$primary: #2d7cc0;
$link: $primary;
$widescreen-enabled: false;
$fullhd-enabled: false;
