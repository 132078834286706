.Logo {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  display: flex;
  align-items: center;

  img {
    max-height: 52px !important;
  }
}

.Nav {
  flex-wrap: wrap;
}
