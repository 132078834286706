.Progress {
  position: relative;

  progress {
    margin-bottom: 0 !important;
  }
}

.Label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 0.8rem;
}