.Spinner {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 40px 0;
  background: #fff;
  opacity: 1;
  transition: opacity .3s;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
