.Detail {
  position: relative;

  &.Clickable {
    cursor: pointer;
  }

  &:hover, &.Loading {
    .Icon {
      display: flex
    }
  }
}

.Icon {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
}
