@import '../../styles/variables';

.Customers {
  table {
    font-size: 0.9rem;
  }
}

.Customer {

}

.Username {
  color: $gray5;

  i {
    margin-right: 5px;
  }
}

tr.Expired {
  td {
    background-color: lighten($danger, 10);
  }
}
